import React from 'react';
import PropTypes from 'prop-types';

// Logo
const logoRed = 'https://s3.amazonaws.com/sally-dashboard-assets/logo/red.svg';
const logoWhite =
  'https://s3.amazonaws.com/sally-dashboard-assets/logo/white.svg';
const logoYellow =
  'https://s3.amazonaws.com/sally-dashboard-assets/logo/yellow.svg';
const logoOrange =
  'https://s3.amazonaws.com/sally-dashboard-assets/logo/orange.svg';

const logo = {
  red: logoRed,
  white: logoWhite,
  yellow: logoYellow,
  orange: logoOrange,
};

const Logo = ({color, ...props}) => (
  <img src={logo[color]} alt="drive_sally_logo" {...props} />
);

Logo.propTypes = {
  color: PropTypes.oneOf(['red', 'white', 'yellow', 'orange']),
};

Logo.defaultProps = {
  color: 'red',
};

export default Logo;
